import {Outlet} from "react-router-dom";
// Components
import {AuthenticatedTopBar} from "./components";
// @MUI
import {Box, styled} from "@mui/material";


const RootStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'none',
});

const MainStyle = styled(Box)(() => ({
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
}));

const BackGroundStyle = styled('div')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    opacity: 0.2
    ,
    backgroundImage: 'url("/static/background_pattern.png")',
    repeat: 'none',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    backgroundSize: '100% auto',
    zIndex: -1
}));

// Dashboard UI Layout Wrapper for Authenticated user
function Dashboard() {

    return (
        <>
            <RootStyle>
                <AuthenticatedTopBar/>
                <MainStyle>
                    <BackGroundStyle/>
                    <Outlet/>
                </MainStyle>
            </RootStyle>
        </>
    )
}

export default Dashboard;