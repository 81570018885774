import {useEffect} from "react";
import {Navigate, Route, Routes} from 'react-router-dom'
// Components
import {DashboardLayout, MinimalLayout} from "../layouts";
import {AuthenticationCheck, RequireAuth} from "./routerUtils";
// Pages
import {Pending, Logout, Main, NotFound} from "../Pages";
// Store
import {useAppDispatch, useAppSelector} from "../store/store";
import {isAuthenticated} from "../store/user/userSelector";
import useNotifier from "../hooks/useNotifier";
import {checkUserAuth} from "../store/user/userFunction";


/**
 * Handles the Routing for the application
 *
 * Protect routes  from unauthorised access
 * If user has logged in reauthenticates users and redirects to page
 *
 */
export default function Router() {
    const dispatch = useAppDispatch();

    const authenticated = useAppSelector(state => isAuthenticated(state));

    // Check if Active user session
    useEffect(() => {
        if (!authenticated) {
            dispatch(checkUserAuth())
        }
    }, [authenticated, dispatch])

    // Introducers Notifiers
    useNotifier();

    return (
        <Routes>
            <Route path='/logout' element={<Logout />} />

            <Route path='/app' element={<RequireAuth authenticated={authenticated}><DashboardLayout/></RequireAuth>} >
                <Route path='/app' element={<Navigate to={'/app/home'} replace={true}/>} />
                <Route path='/app/home' element={<Main />} />
            </Route>


            <Route path='/' element={<AuthenticationCheck authenticated={authenticated}><MinimalLayout/></AuthenticationCheck>}>
                <Route path='/' element={<Pending />}/>
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}