// Components
import {Logo, Profile} from "./index";

//@MUI
import {AppBar, Box, Toolbar} from "@mui/material";

// Generic TopBar Component for UI Layout
function AuthenticatedTopBar() {
    return (
        <>
            <AppBar position='static'>
                <Toolbar
                    disableGutters
                >
                    <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mx: 2}}>
                        <Box sx={{width: 45}}/>
                        <Logo/>
                        <Profile />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default AuthenticatedTopBar;